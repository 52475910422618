import { createSlice } from '@reduxjs/toolkit'
import { store } from "../store";
const initialState = {
  quiz: false,
}

export const quizStartSlice = createSlice({
  name: 'quizStart',
  initialState,
  reducers: {
    negative: (state) => {
      state.quiz = false
    },
    positive: (state) => {
        state.quiz = true
    },
  },
})

// Action creators are generated for each case reducer function
export const { negative, positive } = quizStartSlice.actions

export default quizStartSlice.reducer