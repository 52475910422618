import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { imgError, isLogin } from "../../utils";
import { useSelector } from "react-redux";
const Logo = ({ image,setIsActive }) => {

     // store data get
    const userData = useSelector((state) => state.User);
    let userName = "";
    const checkUserData = (userData) => {
        if (userData.data && userData.data.name != "") {
          return (userName = userData.data.name);
        } else if (userData.data && userData.data.email != "") {
          return (userName = userData.data.email);
        } else {
          return (userName = userData.data.mobile);
        }
      };

    return (
        // <div className="row justify-content-start align-items-center">
        <>
        <div className="col-2 col-lg-1 header-logo">
          <Link to="/" onClick={() => setIsActive(false)}> 
            <img className="dark-logo"src={require("../../assets/images/jlr-logo.png")} alt=" Logo" />
          </Link>
        </div>
        {/* <div className="col-6 col-lg-2">
          <div className="userName">
            <h5>{isLogin() && checkUserData(userData)}</h5>
            <p className="d-flex">Level: <span>Begginer</span></p>
          </div>
        </div> */}
       <div className="col-10 col-lg-3 d-flex justify-content-end align-items-center order-xl-1">
  <div className="prop__title m-2 text-right">
    <h6 className="mb-0">Welcome,  {userData.data && userData.data.name}</h6>
  </div>
  <div className="profileImage ml-2">
    <Link to="/profile" onClick={() => setIsActive(false)}>
      <img className="profile" src={userData.data && userData.data.profile ? userData.data.profile : process.env.PUBLIC_URL + "/images/user.svg"} alt="Logo" />
    </Link>
  </div>
</div>


        </>
        // </div>
    );
};

Logo.propTypes = {
    image: PropTypes.string,
};

export default Logo;
