import React, { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { negative } from '../../store/reducers/quizStartSlice';
const NavBar = ({ t }) => {
    const quizState = useSelector((state) => state.quizStart.quiz);
    const dispatch = useDispatch()
    const location = useLocation();
    const [curScreen, setCurScreen] = useState(location.pathname)

    const isActive = (to) => {   
      return location.pathname === to;
    };
    const endQuiz = () => {
        dispatch(negative());
    };
    const handleNavLinkClick = (to, event) => {
        if (quizState) {
            const confirmed = window.confirm("Are you sure you want to leave the quiz?");
            if (confirmed) {
                endQuiz();
                setCurScreen(to);  // Allow navigation
            } else {
                event.preventDefault();
            }
        } 
    };
    
      

    return (
        <nav className="site-main-menu">
            <ul>
                <li>
                    <NavLink to={"/"} onClick={(event) => handleNavLinkClick("/",event)} className={isActive("/") ? "navbar__link--active" : ""}>
                        <span className="menu-text">{t("Home")}</span>
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink to={"/quiz-play"} className={isActive("/quiz-play") ? "navbar__link--active" : ""}>
                        <span className="menu-text">{t("Quiz Play")}</span>
                    </NavLink>
                </li> */}
                <li>
                    <NavLink to={"/profile/invite-friends"}  onClick={(event) => handleNavLinkClick("/profile/invite-friends",event)} className={isActive("/profile/invite-friends") ? "navbar__link--active" : ""}>
                        <span className="menu-text">{t("Invite Friends")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/instruction"}   onClick={(event) => handleNavLinkClick("/instruction",event)} className={isActive("/instruction") ? "navbar__link--active" : ""}>
                        <span className="menu-text">{t("Instruction")}</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={"/leaderboard"}  onClick={(event) => handleNavLinkClick("/leaderboard",event)} className={isActive("/leaderboard") ? "navbar__link--active" : ""}>
                        <span className="menu-text">{t("Ranking")}</span>
                    </NavLink>
                </li>
                <li className="has-children">
                    <NavLink to="#">
                        <span className="menu-text">{t("More")}</span>
                    </NavLink>
                    <span className="menu-toggle">
                        <i className="">
                            <FaAngleDown />
                        </i>
                    </span>
                    <ul className="sub-menu">
                        <li>
                            <NavLink to={"/contact-us"} className={isActive("/contact-us") ? "navbar__link--active" : ""}>
                                <span className="menu-text">{t("Contact Us")}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/about-us"} className={isActive("/about-us") ? "navbar__link--active" : ""}>
                                <span className="menu-text">{t("About Us")}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/terms-conditions"} className={isActive("/terms-conditions") ? "navbar__link--active" : ""}>
                                <span className="menu-text">{t("Terms and Conditions")}</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/privacy-policy"} className={isActive("/privacy-policy") ? "navbar__link--active" : ""}>
                                <span className="menu-text">{t("Privacy Policy")}</span>
                            </NavLink>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
};

export default withTranslation()(NavBar);
